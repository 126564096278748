import * as React from "react";

const addressLine = (streetAddr: string) => {
    return streetAddr ? (
        <>
            {streetAddr}
            <br />
        </>
    ) : null;
};

const addressNeedsComma = (city?: string, state?: string, zip?: string) => {
    return city && (state || zip);
};

const needSpaceBeforeZip = (city?: string, state?: string, zip?: string) => {
    return (city || state) && zip;
};

export const twoLineFormattedAddress = (
    streetAddr?: string,
    city?: string,
    state?: string,
    zip?: string,
    className?: string,
) => {
    streetAddr = streetAddr || "";
    city = city || "";
    state = state || "";
    zip = zip || "";

    return (
        <p className={`${className || "larger-font"}`}>
            {addressLine(streetAddr)}
            {city +
                (addressNeedsComma(city, state, zip) ? ", " : "") +
                state +
                (needSpaceBeforeZip(city, state, zip) ? " " : "") +
                zip}
        </p>
    );
};
