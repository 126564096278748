import React, { ComponentType, FunctionComponent, useCallback } from "react";
import { useDropzone } from "react-dropzone";

export interface MakeDropzoneProps {
    accept?: string;
    onDropFiles: (acceptedFiles: File[]) => void;
}

export const makeDropzone = <P extends MakeDropzoneProps>(
    Component: ComponentType<P>,
): ComponentType<P> => {
    // eslint-disable-next-line react/display-name
    return ({ accept = "*", onDropFiles, ...props }) => {
        const onDrop = useCallback(
            (acceptedFiles: File[]) => {
                if (acceptedFiles.length > 0) {
                    onDropFiles(acceptedFiles);
                }
            },
            [onDropFiles],
        );
        const { getRootProps, getInputProps } = useDropzone({
            onDrop,
            accept,
            noClick: true,
        });

        return (
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <Component {...(props as P)} />
            </div>
        );
    };
};

export interface DropzoneProps {
    accept?: string;
    onDropFiles: (acceptedFiles: File[]) => void;
    children: React.ReactNode;
}

export const Dropzone: FunctionComponent<DropzoneProps> = ({
    accept = "*",
    onDropFiles,
    children,
}) => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                onDropFiles(acceptedFiles);
            }
        },
        [onDropFiles],
    );
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept,
        noClick: true,
    });

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>Drag and drop some files here</p>
            {children}
        </div>
    );
};
