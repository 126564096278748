import * as date from "@sp-crm/core";
import { displayName, truncatedDisplayName } from "@sp-crm/core";
import * as address from "./address";
import * as browser from "./browser";
import * as email from "./email";
import { mapToArray, splitToColumns } from "./generic";
import * as phone from "./phone";
import { getStatesArray } from "./states";
import * as text from "./text";
import * as uri from "./uri";

const util = {
    mapToArray,
    splitToColumns,
    user: {
        displayName,
        truncatedDisplayName,
    },
    date,
    browser,
    text,
    email,
    uri,
    address,
    getStatesArray,
    phone,
};

export default util;
