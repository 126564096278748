export function displayableUri(uri: string, countDisplayChars?: number): string | null {
    if (uri) {
        // return the full string if we didn't get a value for countDisplayChars, or if the string length is
        // about the same length or less as countDisplayChars.
        // Note: we subtract 3 here because it looks stupid to display something like www.google.co... when
        // we could display www.google.com (I only want to show the ...) if we're actually going to abbreviate
        // at least three chars.
        if (countDisplayChars == null || uri.length - 3 <= countDisplayChars) return uri;

        return uri.substring(0, countDisplayChars) + "…";
    }

    return null;
}

// note that this function will only prepend the prefix string if the baseString is not empty
function ensurePrefix(
    baseString: string,
    prefixCheck: string,
    prefixAppend?: string,
): string {
    if (baseString) {
        const alreadyBeginsWithPrefix: boolean = baseString.indexOf(prefixCheck) == 0;

        if (alreadyBeginsWithPrefix) {
            // string already includes the prefixCheck string, so we're done
            return baseString;
        }

        // if a specific prefixAppend string has been provided, use that;
        // else just prepend the prefixCheck string
        return prefixAppend ? prefixAppend + baseString : prefixCheck + baseString;
    }
    return "";
}

export function sanitizeUri(uri: string): string {
    return ensurePrefix(uri, "http", "http://");
}

export function phoneUri(uri: string): string {
    return ensurePrefix(uri, "tel:");
}

export function isUrl(value: string): boolean {
    return !!(value || "").match(
        /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/g,
    );
}
