import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { PencilIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { FileRenderInfo, PhotoId } from "@sp-crm/core";
import { Modal } from "components/ui/panel/modal";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { TextArea } from "components/ui/textarea";
import { XYCoord } from "dnd-core";
import { GetPhotosForEntityQuery } from "generated/graphql";
import { produce } from "immer";
import update from "immutability-helper";
import React, {
    Fragment,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Byline from "../byline";
import { Header } from "../header";
import { makeDropzone } from "./dropzone";

const DEFAULT_URL_PREFIX = "/api/communities";
const GUEST_URL_PREFIX = "/api/guest/communities";

let isMoving = false;

export type UploadedPhoto = GetPhotosForEntityQuery["getPhotosForEntity"][number];

const imageRenderInfo: FileRenderInfo = {
    fileGroupIcon: "55_photos",
    fileGroupHeading: "Community Photos",
};

interface MultiplePhotoUploadProps {
    photos: UploadedPhoto[];
    onAddNewPhotos: (photos: File[]) => Promise<void>;
    onDeletePhoto: (photoId: PhotoId) => void;
    onUpdatePhotoOrders: (photos: Partial<UploadedPhoto>[]) => void;
    onUpdatePhotoDescription: (photoId: PhotoId, description: string) => Promise<void>;
    title?: string;
}

export const MultiplePhotoUpload: FunctionComponent<MultiplePhotoUploadProps> = props => {
    const [isUploading, setUploading] = useState(false);
    const [isSlidePanelOpen, setSlidePanelOpen] = useState(false);
    const [initialPhotoIndex, setInitialPhotoIndex] = useState(0);
    const [editPhoto, setEditPhoto] = useState(null);
    const {
        photos,
        onAddNewPhotos,
        onUpdatePhotoDescription,
        onDeletePhoto,
        onUpdatePhotoOrders,
    } = props;

    const handleAddNewPhotos = useCallback(
        (files: File[]) => {
            if (!isMoving) {
                setUploading(true);
                onAddNewPhotos(files).then(() => {
                    setUploading(false);
                });
            }
        },
        [onAddNewPhotos, setUploading],
    );

    const handleClickPhoto = useCallback(
        (index: number) => {
            setSlidePanelOpen(true);
            setInitialPhotoIndex(index);
        },
        [setSlidePanelOpen, setInitialPhotoIndex],
    );

    const handleEditPhoto = useCallback(
        (index: number) => {
            setEditPhoto(photos[index]);
        },
        [photos, setEditPhoto],
    );

    const handleUpdatePhotoDescription = useCallback(
        async (photoId: PhotoId, description: string) => {
            await onUpdatePhotoDescription(photoId, description);
            setEditPhoto(null);
        },
        [onUpdatePhotoDescription, setEditPhoto],
    );

    const handleDeletePhoto = useCallback(
        async (photoId: PhotoId) => {
            onDeletePhoto(photoId);
            setEditPhoto(null);
        },
        [onDeletePhoto, setEditPhoto],
    );

    return (
        <div className="input-form-block-no-bottom-margin multiple-image-uploader">
            <div className="empty-additional-contacts-section flex-row-no-bottom-margin">
                <div className="flex-column">
                    <div className="header-with-action">
                        <div className="header">
                            <Header iconName={imageRenderInfo.fileGroupIcon}>
                                {imageRenderInfo.fileGroupHeading}
                            </Header>
                        </div>
                    </div>
                </div>
                <div className="flex-row">
                    {isUploading && <span>Uploading...&nbsp;&nbsp;&nbsp;</span>}
                    {photos.length > 0 && (
                        <SecondaryButton
                            className="view-slide-button basic-button"
                            title="View Slide Show"
                            onClick={() => handleClickPhoto(0)}>
                            View Slide Show
                        </SecondaryButton>
                    )}
                    <AddPhotosButton onAddNewPhotos={handleAddNewPhotos} />
                </div>
            </div>

            <div>
                <DroppablePhotos
                    accept="image/*"
                    photos={photos}
                    onDropFiles={handleAddNewPhotos}
                    onUpdateOrders={onUpdatePhotoOrders}
                    onClickPhoto={handleClickPhoto}
                    onEditPhoto={handleEditPhoto}
                />
            </div>

            <SlideModal
                isOpen={isSlidePanelOpen}
                onDismiss={() => setSlidePanelOpen(false)}
                photos={photos}
                initialPhotoIndex={initialPhotoIndex}
                urlPrefix={DEFAULT_URL_PREFIX}
                title={props.title}
            />

            <EditPhotoPanel
                onDismiss={() => setEditPhoto(null)}
                onUpdatePhotoDescription={handleUpdatePhotoDescription}
                onDeletePhoto={handleDeletePhoto}
                initialPhoto={editPhoto}
                urlPrefix={DEFAULT_URL_PREFIX}
            />
        </div>
    );
};

const PhotoPathContext = React.createContext<{ urlPrefix: string }>({
    urlPrefix: DEFAULT_URL_PREFIX,
});

interface GuestExperienceContextProps {
    token: string;
    children: React.ReactNode;
}

export const GuestExperienceContext: FunctionComponent<
    GuestExperienceContextProps
> = props => {
    const { children, token } = props;
    return (
        <PhotoPathContext.Provider value={{ urlPrefix: `${GUEST_URL_PREFIX}/${token}` }}>
            {children}
        </PhotoPathContext.Provider>
    );
};

export const PhotoViewer: FunctionComponent<{
    photos: UploadedPhoto[];
    imageRenderInfo: FileRenderInfo;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    viewerRef?: (ref: { showPhotos: (args: any) => void }) => void;
    title?: string;
    includeButton?: boolean;
}> = props => {
    const urlPrefix = useContext(PhotoPathContext).urlPrefix;
    const [isSlidePanelOpen, setSlidePanelOpen] = useState(false);
    const [initialPhotoIndex, setInitialPhotoIndex] = useState(0);
    const includeButton = props.includeButton || false;
    const { photos } = props;

    const onClickPhoto = useCallback((index: number) => {
        setSlidePanelOpen(true);
        setInitialPhotoIndex(index);
    }, []);

    useEffect(() => {
        props.viewerRef && props.viewerRef({ showPhotos: setSlidePanelOpen });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const buttonRef = useRef(null);

    return (
        <div className="multiple-image-uploader">
            <div className="community-photos__container">
                {photos.map((photo, index) => (
                    <PhotoThumbnail
                        key={photo.id}
                        urlPrefix={urlPrefix}
                        photo={photo}
                        onClickPhoto={() => onClickPhoto(index)}
                    />
                ))}
            </div>
            {includeButton ? (
                <SecondaryButton ref={buttonRef} onClick={() => setSlidePanelOpen(true)}>
                    View Slide Show
                </SecondaryButton>
            ) : null}
            <SlideModal
                urlPrefix={urlPrefix}
                isOpen={isSlidePanelOpen}
                onDismiss={() => {
                    setSlidePanelOpen(false);
                    buttonRef && buttonRef.current && buttonRef.current.focus();
                }}
                photos={photos}
                initialPhotoIndex={initialPhotoIndex}
                title={props.title}
            />
        </div>
    );
};

const AddPhotosButton: FunctionComponent<{
    onAddNewPhotos: (photos: File[]) => void;
}> = ({ onAddNewPhotos }) => {
    const fileRef = useRef(null);

    const handleUpload = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = Array.from(event.target.files) as File[];
            if (files.length) {
                onAddNewPhotos(files);
            }
        },
        [onAddNewPhotos],
    );

    return (
        <div className="add-another-community-photo-button">
            <SecondaryButton
                title="Add Photos"
                onClick={() => {
                    fileRef.current.click();
                }}>
                Add Photos
            </SecondaryButton>
            <input
                type="file"
                ref={fileRef}
                className="display--none"
                accept="image/*"
                multiple
                onChange={handleUpload}
            />
        </div>
    );
};

const Photo: FunctionComponent<{
    photo: UploadedPhoto;
    urlPrefix: string;
    height?: number;
    // eslint-disable-next-line react/display-name
}> = React.memo(({ photo, height, urlPrefix }) => {
    const ref = useRef(null);
    const [isLoaded, setLoaded] = useState(false);

    const onImageNotFound = useCallback(() => {
        ref.current.src = `https://www.gravatar.com/avatar/${photo.id}?s=${
            height || 128
        }&d=identicon`;
    }, [ref, photo.id, height]);

    return (
        <Fragment>
            <img
                className="photo"
                onLoad={() => setLoaded(true)}
                src={`${urlPrefix}/${photo.entityId}/photos/${photo.id}/photo/${
                    height || ""
                }`}
                ref={ref}
                onError={onImageNotFound}
            />
            {!isLoaded && <span className="placeholder">Loading...</span>}
        </Fragment>
    );
});

const PhotoThumbnail: FunctionComponent<{
    photo: UploadedPhoto;
    onClickPhoto: () => void;
    onEditPhoto?: () => void;
    urlPrefix: string;
    // eslint-disable-next-line react/display-name
}> = React.memo(({ photo, onClickPhoto, onEditPhoto, urlPrefix }) => (
    <div className="photo-thumbnail" onClick={onEditPhoto || null}>
        <Photo urlPrefix={urlPrefix} photo={photo} height={128} />
        <div className="photo-thumbnail__actions space-x-1">
            <button
                onClick={e => {
                    e.stopPropagation();
                    onClickPhoto();
                }}
                type="button">
                <PhotoIcon className="w-8 h-8 bg-white p-1" />
            </button>

            {onEditPhoto && (
                <button onClick={onEditPhoto} type="button">
                    <PencilIcon className="w-8 h-8 bg-white p-1" />
                </button>
            )}
        </div>
    </div>
));

interface DragItem {
    index: number;
    id: string;
    type: string;
}
interface DraggablePhotoProps {
    id: PhotoId;
    photo: UploadedPhoto;
    index: number;
    movePhoto: (dragIndex: number, hoverIndex: number) => void;
    onDrop: () => void;
    onClickPhoto: () => void;
    onEditPhoto: () => void;
    urlPrefix: string;
}

const DraggablePhoto: FunctionComponent<DraggablePhotoProps> = ({
    id,
    photo,
    index,
    movePhoto,
    onDrop,
    onClickPhoto,
    onEditPhoto,
    urlPrefix,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: "photo",
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            movePhoto(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop() {
            onDrop();
            isMoving = false;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "photo",
        item: { type: "photo", id, index },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity }}>
            <PhotoThumbnail
                urlPrefix={urlPrefix}
                photo={photo}
                onClickPhoto={onClickPhoto}
                onEditPhoto={onEditPhoto}
            />
        </div>
    );
};

const Photos: FunctionComponent<{
    photos: UploadedPhoto[];
    onUpdateOrders: (photos: Partial<UploadedPhoto>[]) => void;
    onClickPhoto: (index: number) => void;
    onEditPhoto: (index: number) => void;
    urlPrefix?: string;
}> = ({ photos, onUpdateOrders, onClickPhoto, onEditPhoto, urlPrefix }) => {
    urlPrefix = urlPrefix || DEFAULT_URL_PREFIX;
    const [innerPhotos, setInnerPhotos] = useState(photos);

    const movePhoto = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            isMoving = true;
            const dragPhoto = innerPhotos[dragIndex];
            setInnerPhotos(
                update(innerPhotos, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragPhoto],
                    ],
                }),
            );
        },
        [innerPhotos, setInnerPhotos],
    );

    const onDrop = useCallback(() => {
        onUpdateOrders(
            innerPhotos.map((photo, index) => ({
                id: photo.id,
                order: index,
            })),
        );
    }, [innerPhotos, onUpdateOrders]);

    useEffect(() => {
        setInnerPhotos(photos);
    }, [photos]);

    return (
        <div className="community-photos__container">
            {innerPhotos.map((photo, index) => (
                <DraggablePhoto
                    urlPrefix={urlPrefix}
                    key={photo.id}
                    index={index}
                    id={photo.id}
                    photo={photo}
                    movePhoto={movePhoto}
                    onDrop={onDrop}
                    onClickPhoto={() => onClickPhoto(index)}
                    onEditPhoto={() => onEditPhoto(index)}
                />
            ))}
        </div>
    );
};

const DroppablePhotos = makeDropzone<{
    accept: string;
    photos: UploadedPhoto[];
    onDropFiles: (files: File[]) => void;
    onUpdateOrders: (photos: UploadedPhoto[]) => void;
    onClickPhoto: (index: number) => void;
    onEditPhoto: (index: number) => void;
}>(Photos);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
const SlickButtonFix: FunctionComponent<any> = ({ children, ...props }) => (
    <span {...props}>{children}</span>
);

const SlideModal: FunctionComponent<{
    isOpen: boolean;
    initialPhotoIndex?: number;
    onDismiss: () => void;
    photos: UploadedPhoto[];
    urlPrefix: string;
    title?: string;
}> = ({ isOpen, onDismiss, photos, initialPhotoIndex = 0, urlPrefix, title }) => {
    const [index, setIndex] = useState(0);
    const ref = React.useRef<Slider>(null);

    const settings = useMemo(
        () => ({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            nextArrow: (
                <SlickButtonFix>
                    <ChevronRightIcon className="w-8 h-8" />
                </SlickButtonFix>
            ),
            prevArrow: (
                <SlickButtonFix>
                    <ChevronLeftIcon className="w-8 h-8" />
                </SlickButtonFix>
            ),
            afterChange: setIndex,
        }),
        [],
    );

    useEffect(() => {
        if (isOpen) {
            setIndex(initialPhotoIndex);
            setTimeout(() => {
                ref.current.slickGoTo(initialPhotoIndex, false);
            });
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!photos || !photos[index]) {
        return <></>;
    }

    return (
        <Modal headerText="" isOpen={isOpen} onDismiss={onDismiss}>
            <div className="slide-photo__container">
                {title && <h3 className="slide-photo__description">{title}</h3>}
                <p>
                    {index + 1}/{photos.length}
                </p>
                <Slider {...settings} ref={ref}>
                    {photos.map(photo => (
                        <div className="slide-photo" key={photo.id}>
                            <Photo urlPrefix={urlPrefix} photo={photo} />
                        </div>
                    ))}
                </Slider>
                <h3 className="slide-photo__description">
                    {photos[index].originalFile.description || ""}
                </h3>
            </div>
        </Modal>
    );
};

const EditPhotoPanel: FunctionComponent<{
    initialPhoto?: UploadedPhoto;
    onDismiss: () => void;
    onUpdatePhotoDescription: (photoId: PhotoId, description: string) => void;
    onDeletePhoto: (photoId: PhotoId) => void;
    urlPrefix: string;
}> = ({
    initialPhoto,
    onDismiss,
    onUpdatePhotoDescription,
    onDeletePhoto,
    urlPrefix,
}) => {
    const [photo, setPhoto] = useState<UploadedPhoto>(initialPhoto);

    useEffect(() => {
        setPhoto(initialPhoto);
    }, [initialPhoto]);

    return (
        <Panel
            isOpen={!!photo}
            onDismiss={onDismiss}
            type={PanelType.medium}
            headerText="Edit Photo">
            {photo && (
                <Fragment>
                    <div className="photo-info__container">
                        <div className="photo-info__photo">
                            <Photo urlPrefix={urlPrefix} photo={photo} />
                        </div>
                        <div className="photo-info">
                            <p className="photo-info__filename">
                                {photo.originalFile.originalFilename}
                            </p>
                            <Byline
                                entity={{
                                    authorId: photo.createdBy,
                                    dateAdded: photo.dateAdded,
                                }}
                            />
                        </div>
                    </div>
                    <div className="edit-photo__description">
                        <TextArea
                            label="Photo Caption"
                            rows={5}
                            value={photo.originalFile.description}
                            autoGrow
                            onChange={e =>
                                setPhoto(
                                    produce(photo, draft => {
                                        draft.originalFile.description = e.target.value;
                                    }),
                                )
                            }
                        />
                    </div>

                    <div className="flex-row-no-bottom-margin edit-photo__actions">
                        <SecondaryButton
                            className="edit-photo__action"
                            title="Cancel"
                            onClick={onDismiss}>
                            Cancel
                        </SecondaryButton>

                        <PrimaryButton
                            className="edit-photo__action"
                            title="Update"
                            onClick={() =>
                                onUpdatePhotoDescription(
                                    photo.id,
                                    photo.originalFile.description,
                                )
                            }>
                            Update
                        </PrimaryButton>

                        <SecondaryButton
                            className="edit-photo__action"
                            title="Delete"
                            onClick={() => onDeletePhoto(photo.id)}>
                            Delete
                        </SecondaryButton>
                    </div>
                </Fragment>
            )}
        </Panel>
    );
};
