import { IAttributableEntity, User } from "@sp-crm/core";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";
import util from "../../util";

interface BylineProps {
    dateAdded?: Date;
    author?: User;
    wasEdited?: boolean;
}

class Byline extends React.Component<BylineProps, undefined> {
    render() {
        return (
            <div className="byline-control">
                Added by {util.user.displayName(this.props.author)}{" "}
                {util.date.ago(this.props.dateAdded)}
                {this.props.wasEdited ? " (edited)" : ""}
            </div>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function mapStateToProps(state: ApplicationState, ownProps: any): BylineProps {
    const entity = ownProps.entity as IAttributableEntity;
    const authorId = entity.authorId;
    const val = {
        dateAdded: entity.dateAdded,
        author: state.users.users[authorId],
    };
    return val;
}
export default connect(mapStateToProps)(Byline);
