import * as _ from "lodash";
import sanitizeHtml from "sanitize-html";

const zeroes = "0px 0px 0px 0px";

/**
 * `inlineStylesForDeliver` inlines a set of known
 * important styles for delivery by email.
 * It runs client-side because a DOM is easily available
 * in the browser, and is more difficult to load on the server.
 * @param body  User supplied email body.
 * @returns     Email body as string with styles inlined.
 */
export function inlineStylesForDeliver(body: string): string {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = body;
    _.each(wrapper.getElementsByTagName("p"), p => {
        p.style.padding = zeroes;
        p.style.margin = zeroes;
    });
    return wrapper.innerHTML;
}

interface SanitizedMarkUp {
    __html: string;
}

export const getSanitizedMarkUpFromEmailBody = (htmlBody: string): SanitizedMarkUp => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlBody, "text/html");
    const dirtyHtml = parsedHtml.body.innerHTML;

    const colorCodeRegexMatchers = {
        hex: /^#(0x)?[0-9a-f]+$/i,
        rgb: /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
        name: /^(blue|violet|orange|gray)$/,
    };

    const cleanHtml = sanitizeHtml(dirtyHtml, {
        allowedTags: [
            "br",
            "u",
            "b",
            "i",
            "em",
            "strong",
            "a",
            "p",
            "span",
            "h1",
            "h2",
            "ul",
            "ol",
            "li",
        ],
        allowedAttributes: {
            a: ["href", "target"],
            p: ["style"],
            span: ["style"],
        },
        allowedStyles: {
            "*": {
                // Match HEX and RGB and ColorNames
                color: [
                    colorCodeRegexMatchers.hex,
                    colorCodeRegexMatchers.rgb,
                    colorCodeRegexMatchers.name,
                ],
                "background-color": [
                    colorCodeRegexMatchers.hex,
                    colorCodeRegexMatchers.rgb,
                    colorCodeRegexMatchers.name,
                ],
                "text-align": [/^left$/, /^right$/, /^center$/],
                // Match any number with px, em, or %.
                "font-size": [/^\d+(?:px|em|%)$/],
            },
            p: {
                // Match any number with only rem.
                "font-size": [/^\d+rem$/],
                // Match any number with px, or em.
                padding: [/^\d+(?:px|em)$/],
                margin: [/^\d+(?:px|em)$/],
            },
        },
    });

    return { __html: cleanHtml };
};
